import { useState, useEffect } from "react";

const isClient = typeof window === "object";

export const useMediaQuery = (mediaQuery) => {
  const [isVerified, setIsVerified] = useState(
    isClient ? !!window.matchMedia(mediaQuery).matches : null
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);

    if ("addEventListener" in mediaQueryList) {
      mediaQueryList.addEventListener("change", documentChangeHandler);
    } else {
      // Deprecated signature required for Safari 13
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();
    return () => {
      if ("removeEventListener" in mediaQueryList) {
        mediaQueryList.removeEventListener("change", documentChangeHandler);
      } else {
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);

  return isVerified;
};
